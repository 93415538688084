import React from 'react';
import fire from "../../config/shit";
import firebase from "firebase";

class FormForgot extends React.Component {
    constructor(props) {
        super(props)


        this.state = {
            email: ""
        }

        this.handleChange = this.handleChange.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
      }

      resetPassword(e) {
        e.preventDefault();

        var emailAddress = this.state.email;

        fire.auth().sendPasswordResetEmail(emailAddress).then(function() {
        console.log('email sent!');
        }).catch(function(error) {
    // An error happened.
    console.log(error)
        });

      }

      

    render() {

        return (

        <div>hello</div>
        )
    }
}

export default FormForgot;